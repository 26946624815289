
const  logo =  require('../img/logo-iasoft.png');


export default function Maintenance(props) {




    return (
        <div className="container">
            <div className='panel'>
                <img src={logo} alt="logo"/>

                <div className='text-manutencao'>
                Site em Manuten&ccedil;&atilde;o
                </div>

            </div>
            
            
        </div>
    );
}
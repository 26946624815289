
import './App.css';
import './css/Maintenance.css';
// import BottomMenu from './components/BottomMenu';
// import BoxLeft from './components/BoxLeft';
// import BoxRight from './components/BoxRight';
// import Footer from './components/Footer';

// import Middle from './components/Middle';
// import Slideshow from './components/Slideshow';
// import Top from './components/Top';
// import './css/Default.css';
// import './css/Global.css';
// import './css/highslide.css';
// import './css/Internal.css';
// import './css/Modal.css';

import Maintenance from './components/Maintenance';


function App() {

  return (
    <div className="App">
      <Maintenance />
    </div>
  );

  // return (
  //   <div >
  //     <div className="wrapper">
  //       <header >
  //         <Top/>
  //       </header>
  //       <Slideshow/>
  //       <Middle/>

  //       <div className="bottom">
  //         <BoxLeft />
  //         <BoxRight />
  //       </div>

  //       <BottomMenu />
  //     </div>
  //     <Footer />
  //   </div>
  // );
}

export default App;
